import React from 'react'
import { Router } from '@reach/router'
import Login from '../components/login/login.component'
import Dashboard from '../components/admin/dashboard.component'
import PrivateRoute from '../components/protected-route.component'

const AdminPage = () => (
  <Router>
    <PrivateRoute path="/clientdashboard/dashboard" component={Dashboard} />
    <Login path="/clientdashboard/login" />
  </Router>
)

export default AdminPage
