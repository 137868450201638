import React, { useState } from 'react'
import { navigate } from '@reach/router'
import Layout from '../layout.component'
import SEO from '../seo.component'
import { handleLogin, isLoggedIn } from '../../../utils/auth'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [incorrectLogin, setIncorrectLogin] = useState('')

  const handleUpdate = type => {
    return event => {
      if (type === 'username') {
        setUsername(event.target.value)
      } else {
        setPassword(event.target.value)
      }
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    handleLogin({
      username,
      password,
      incorrectLogin,
    })

    if (isLoggedIn()) {
      navigate(`/clientdashboard/dashboard`)
    } else {
      // display error message
      setIncorrectLogin(true)
    }
  }

  return (
    <Layout>
      <div className='Header'>
        <div className="Header__overlay"></div>
        <div className="Header__line"></div>
        <div className="Header__text">
          <h1 className="Heading--primary">Login</h1>
        </div>
      </div>
      <div className="Login">
        <form onSubmit={handleSubmit}>
          <small> Login to view your client portal</small>
          <br />
          <input
            type="text"
            name="username"
            onChange={handleUpdate('username')}
            value={username}
            placeholder="Username"
          />
          <input
            type="password"
            name="password"
            onChange={handleUpdate('password')}
            value={password}
            placeholder="Password"
          />
          <button>Log in</button>
          {incorrectLogin && <p>That was an incorrect username and password</p>}
        </form>
      </div>
      <SEO title="Login" />
    </Layout>
  )
}

export default Login
